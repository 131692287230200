import React from 'react';
import Link from 'next/link';
import styled from "styled-components";
import Colors from "../../constants/Colors";
import {deviceMax} from "../../../styles/device";

interface BreadcrumbItem {
    label: string;
    link?: string;
}

interface BreadcrumbProps {
    items: BreadcrumbItem[];
    colorProps?: boolean;
    margin?: boolean;
    className?: string;
}

const GeneralBreadcrumb: React.FC<BreadcrumbProps> = ({items, colorProps = true, margin = true, className}) => {
    return (
        <GeneralBreadcrumbWrapper colorStyle={colorProps} margin={margin} className={className}>
            {items.map((item, index) => (
                <GeneralBreadcrumbItem key={index} colorStyleItem={colorProps}>
                    {item.link ? (
                        <Link href={item.link}>
                            {item.label}&nbsp;&nbsp;
                        </Link>
                    ) : (
                        <span>{item.label}&nbsp;&nbsp;</span>
                    )}
                    {index < items.length - 1 && (
                        <div className="svg-container">
                            <svg width="13" height="25" viewBox="0 0 13 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.3145 23.4122L2.00052 12.1903L11.3145 1" stroke="white" strokeWidth="3" strokeMiterlimit="10"/>
                            </svg>
                        </div>
                    )}
                </GeneralBreadcrumbItem>
            ))}
        </GeneralBreadcrumbWrapper>
    );
};

type GeneralBreadcrumbWrapperProps = {
    colorStyle: boolean;
    margin: boolean;
}

type GeneralBreadcrumbItemProps = {
    colorStyleItem: boolean;
}

export const GeneralBreadcrumbWrapper = styled.div<GeneralBreadcrumbWrapperProps>`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0;
  color: ${(props:GeneralBreadcrumbWrapperProps)=>props.colorStyle ? Colors.white : Colors.black};
  margin-bottom: ${(props:GeneralBreadcrumbWrapperProps)=>props.margin ? '33px' : '0'};

  @media ${deviceMax.tablet} {
    color: ${Colors.black};
    padding-top: ${(props:GeneralBreadcrumbWrapperProps)=>props.margin ? '20px' : '0'};
    margin-bottom: ${(props:GeneralBreadcrumbWrapperProps)=>props.margin ? '75px' : '20px'};
  }
  
  &.mobile-mb-pt {
    @media ${deviceMax.tablet} {
      margin-bottom: 20px;
      padding-top: 0;
    }
  }
  
  &.noColor {
    color: ${Colors.black};
  }
  
  &.general-form{
    @media ${deviceMax.tablet} {
      margin-bottom: 20px;
      padding:0 24px;
    }
  }
`;

const GeneralBreadcrumbItem = styled.div<GeneralBreadcrumbItemProps>`
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-weight: 600;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 0;

    &:is(:not(:first-child)) {
        margin-left: 8px;
    }

    > a, > span {
        @media ${deviceMax.tablet} {
            color: ${Colors.black};
        }
    }

    > a {
        font-weight: 600;
        font-size: 12px;
        line-height: 24px;
        letter-spacing: 0;
        text-decoration: none;
        transition: all .2s ease;
        color: ${(props) => props.colorStyleItem ? Colors.white : Colors.black};

        &:hover {
            color: ${(props) => props.colorStyleItem ? Colors.purpleColor : Colors.brandColor};
        }
        
        @media ${deviceMax.tablet} {
            color: ${Colors.black};
        }

        @media ${deviceMax.mobileM} {
            font-size: 10px;
            line-height: 17px;
        }
    }

    > span {
        font-weight: 600;
        font-size: 12px;
        line-height: 24px;
        letter-spacing: 0;
        color: ${(props) => props.colorStyleItem ? Colors.white : Colors.black};
        
        @media ${deviceMax.tablet} {
            color: ${Colors.black};
        }

        @media ${deviceMax.mobileM} {
            font-size: 10px;
            line-height: 17px;
        }
    }

    .svg-container {

        > svg {
            width: 4px;
            height: 8px;
            transform: rotate(180deg);

            path {
                stroke: ${(props: GeneralBreadcrumbItemProps) => props.colorStyleItem ? Colors.white : Colors.black};
            }
        }

        @media ${deviceMax.tablet} {
            > svg {
                width: 3px;
                height: 6px;

                path {
                    stroke: black;
                }
            }
        }
    }
`;

export default GeneralBreadcrumb;
