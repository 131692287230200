import React from "react";
import styled from "styled-components";
import Colors from "../../constants/Colors";
import {deviceMax, deviceMin} from '../../../styles/device';

interface IProps {
    children?: JSX.Element[] | JSX.Element;
    className?: string;
}

const TextArea = ({children, className}: IProps) => {
    return (
        <TextWrapper className={className}>
            {children}
        </TextWrapper>
    )
}

const TextWrapper = styled.div`
    position: relative;
    width: 100%;
    box-sizing: border-box;
    background: ${Colors.white};
    border-radius: 6px;
    padding: 23px 40px;

    &.mobile-plr0 {

        > h1.mobile-h2-style {
            margin-top: 0;
            color: ${Colors.brandColor};
            letter-spacing: 0;
            font-size: 18px;
            line-height: 55px;
            font-weight: 800;
        }

        > h2.desktop-mt-0,h3.desktop-mt-0 {
            margin-top: 0;
            letter-spacing: 0;
            font-size: 18px;
            line-height: 30px;
            font-weight: 800;
            color: #000000;


            @media ${deviceMax.tablet} {
                margin-top: 30px;
            }
        }

        > h4 {
            font-weight: bold;
        }

        > p.text-brand-color {
            color: ${Colors.brandColor};
        }

        ul > li {
            list-style: disc;
            margin-left: 16px;
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    @media ${deviceMax.tablet} {
        padding-left: 20px;
        padding-right: 20px;
        background: transparent;

        .mobileBlock {
            display: block;
        }

        &.mobile-plr0 {
            padding-left: 0;
            padding-right: 0;

            > h1.mobile-h2-style {
                text-align: center;
                margin-top: 54px;
                color: ${Colors.blackColor};
            }

            ol {
                padding-left: 0;
                margin-left: 0;
            }

            p, li {
                font-size: 14px;
            }
        }
    }
`;

export default TextArea;
