import React from "react";
import TextArea from "../textArea/TextArea";
import {LightingTextWrapper} from "./LightingTextStyled";
import {NavigationURLs} from "../../functions/NavigationFunctions";
import GeneralBreadcrumb from "../breadcrumb/GeneralBreadcrumbComp";

type IProps = {
    showPopup?: boolean;
}

const LightingText = ({
    showPopup = false
                      } : IProps) => {

    const breadcrumbItems = [
        { label: 'Pembe Panjur', link: NavigationURLs.landing },
        { label: 'Aydınlatma Metni'}
    ];

    return (
        <LightingTextWrapper className={showPopup ? 'popup-wrapper' : undefined}>
            <TextArea>
                <GeneralBreadcrumb items={breadcrumbItems} colorProps={false} margin={false}/>
                <h1 className={showPopup ? 'popup-wrapper-title' : undefined}>AYDINLATMA METNİ</h1>
                <p>
                    Kişisel Verilerinizin İşlenme Amacı, Dayanağı ve İlgili Haklarınıza Dair
                </p>

                <h2>
                    Aydınlatma Metni
                </h2>

                <p>
                    Pembe Panjur olarak bizler siz kullanıcılarımıza daha iyi hizmet verebilmek amacıyla ve
                    başta 6698 Sayılı Kişisel Verilerin Korunması Kanun (“Kanun”) kapsamında Veri Sorumlusu
                    sıfatı sahip olmamız ve 6563 Sayılı Elektronik Ticaretin Düzenlenmesi Hakkında Kanun ve
                    ilgili mevzuattan kaynaklanan yasal yükümlülüklerimiz çerçevesinde; söz konusu amaç ve yasal
                    yükümlülüklerimizi yerine getirebilmeyi sağlayacak kişisel verilerinizi (ad, soyad, doğum
                    tarihi, cep telefonu numarası, e-posta, adres, kimlik bilgileri, mesleki bilgiler ve fatura
                    bilgileri gibi) ve özel nitelikli kişisel verilerinizi (ırk, etnik köken, siyasi düşünce,
                    felsefi inanç, kılık kıyafet, dernek, vakıf veya sendika üyeliği, sağlık, cinsel hayat gibi)
                    (kişisel verileriniz ve özel nitelikli kişisel verileriniz birlikte “Kişisel Veriler”) yine
                    anılan yasalara uygun surette toplayabilmekte ve işleyebilmekteyiz.
                </p>

                <p>
                    İşburada ve Kanun’un 5. ve 6. Maddesinde belirtilen hukuki sebeplerle toplanan Kişisel
                    Verileriniz, bugüne kadar olduğu gibi bundan sonra da verdiğiniz açık rızanıza ya da
                    Kanun’da tanınan istisnalara istinaden, işbu Aydınlatma Metni ve Şirketimiz tarafından
                    oluşturulan Kişisel Verilerin İşlenmesi Saklama ve İmha Politikası ile belirlenen amaçlar ve
                    kapsam dışında kullanılmamak kaydıyla, gerekli bilgi güvenliği tedbirleri de alınarak
                    işlenecek ve kanunların emrettiği süreler boyunca saklanacaktır.
                </p>
                <p>
                    Kişisel Verilerinizin işlenme amaçları aşağıda belirtilmiştir.
                </p>

                <ul>
                    <li>
                        Kurumsal sürdürülebilirlik faaliyetlerinin planlanması ve icrası

                    </li>
                    <li>
                        İş ortakları ile olan ilişkilerin yönetimi

                    </li>
                    <li>
                        Potansiyel iş ortakları ile iletişimin sağlanması ve projelerin yönetimi

                    </li>
                    <li>
                        Platform üzerinde üyeliğin sürdürülmesi ve diğer üyelere bilgilerin aktarılması

                    </li>
                    <li>
                        Şirket’in finansal raporlama ve risk yönetimi işlemlerinin icrası/takibi

                    </li>
                    <li>
                        Şirket’in hukuk işlerinin icrası/takibi

                    </li>
                    <li>
                        Kurumsal iletişim faaliyetlerinin planlanması ve icrası

                    </li>
                    <li>
                        Kurumsal yönetim faaliyetlerinin icrası

                    </li>
                    <li>
                        Şirketler ve ortaklık hukuku işlemlerinin gerçekleştirilmesi

                    </li>
                    <li>
                        Veri sahibinin talep ve şikâyet yönetimi

                    </li>
                    <li>
                        Yatırımcı ilişkilerinin yönetilmesi

                    </li>
                    <li>
                        Yetkili devlet kurumlarına mevzuattan kaynaklı bilgi verilmesi

                    </li>
                    <li>
                        Çerez politikası çerçevesinde daha iyi hizmet verebilmek amacıyla kullanıcı hareketi
                        verilerinin analizi ve yurt içinde ve yurt dışında bulunan üçüncü kişilere aktarımı

                    </li>
                    <li>
                        Anlaşmalı çağrı merkezi ve benzeri kurumlara aktarımı

                    </li>
                    <li>
                        Satış, pazarlama ve raporlama faaliyetlerinin yürütülmesi.

                    </li>
                </ul>

                <p>
                    Kişisel Verileriniz elektronik ortamda toplanmakta ve işlenmektedir.
                </p>

                <p>
                    Şirketimiz yukarıda sayılan veri işleme amaçları çerçevesinde elde ettiği Kişisel Verileri
                    Kanun’un 8. Maddesine uygun olarak yurt içi ve yurt dışında bulunan üçüncü kişilere
                    (ortaklarımız, iştiraklerimiz, tedarikçilerimiz, hizmet sağlayıcılarımız, danışmanlarımız,
                    iş birliği yaptığımız şirket veya kişiler de dahil) aktarabilmektedir.
                </p>
                <p>
                    Kişisel Veri sahibi olarak dilediğiniz zaman Kanun’un 11. Maddesi kapsamında Veri Sorumlusu
                    olan Şirketimize başvurarak aşağıda belirtilen hakları kullanabilirsiniz.
                </p>
                <ul>
                    <li>
                        Kendisine ait kişisel verilerin işlenip işlenmediğini öğrenme,
                    </li>
                    <li>
                        İşlenen kişisel verileri varsa bunlara ilişkin bilgi talep etme,
                    </li>
                    <li>
                        Kişisel verilerin işlenme amacını ve bu verilerin amaca uygun kullanılıp kullanılmadığını
                        öğrenme,
                    </li>
                    <li>
                        Kişisel verilerinin aktarıldığı üçüncü kişileri bilme, kişisel verilerindeki hataların
                        düzeltilmesini ve eğer aktarım yapılmışsa ilgili üçüncü
                        kişiden bu düzeltmenin istenmesini talep etme,
                    </li>
                    <li>
                        Kişisel verilerin işlenmesini gerektiren sebeplerin ortadan kalkması halinde Kişisel
                        Verilerin Silinmesi, yok edilmesi veya Anonim Hale
                        Getirilmesi Hakkında Yönetmelik Hükümleri uyarınca bu verilerin otuz gün içerisinde
                        silinmesini, yok edilmesini ya da anonim hale getirilmesini isteme ve eğer aktarım
                        yapılmışsa bu talebin aktarılan üçüncü kişiye iletilmesini isteme,
                    </li>
                    <li>
                        İşlenen verilerin neticesinde kişi ile ilintili olumsuz bir sonuç çıkmasına itiraz etme,
                    </li>
                    <li>
                        Kanun’a aykırı veri işleme nedeniyle zararının ortaya çıkması halinde zararını yasalar
                        çerçevesinde talep etme haklarına sahiptirler.
                    </li>
                </ul>
                <p>
                    Buna göre Şirketimize veri sahibinden gelecek yukarıda yer alan talepleri içeren yazılı
                    başvurulara azami 30 (otuz) gün içerisinde cevap verilerek gerekli işlemlerin yapılması
                    sağlanacaktır. Başvurular, Kişisel Verileri Koruma Kurulu tarafından alınacak karar ile bir
                    ücret tarifesi yayınlamadığı sürece ücretsiz olarak yapılacaktır. Başvurulara verilecek
                    cevapların 10 (on) sayfayı aşması halinde, her sayfa için 1,00 (bir) TL işlem ücreti
                    alınacaktır. Cevabın CD, flash bellek gibi kayıt ortamında verilmesinin istenmesi halinde,
                    istenen kayıt ortamının maliyetine göre ücret talep edilecektir.

                </p>
                <p>
                    Kullanıcılarımız yukarıda sayılan bu taleplerini iletmek amacıyla web sitemizde bulunan
                    Başvuru Formu’nda belirtilen yollarla Şirketimiz ile her zaman iletişime geçebilirler.

                </p>


            </TextArea>
        </LightingTextWrapper>
    )

}

export default LightingText;
