import React from "react";
import * as Styled from "./PopupCompStyled";

interface PopupCompProps {
    children: React.ReactNode;
    onClose: () => void;
}

const PopupComp = ({ children, onClose }: PopupCompProps) => {
    return (
        <Styled.PopupWrapper>
            <Styled.PopupContent>
                <Styled.PopupClose onClick={onClose} >
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                        <path d="M18.5 2L1.5 19" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M1.5 2L18.5 19" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </Styled.PopupClose>
                {children}
                <Styled.PopupCloseButton onClick={onClose}><p>Kapat</p></Styled.PopupCloseButton>
            </Styled.PopupContent>
        </Styled.PopupWrapper>
    );
}

export default PopupComp;