import styled from "styled-components";
import {deviceMax} from "../../../styles/device";
import Colors from "../../constants/Colors";

export const LightingTextWrapper = styled.div`
    position: relative;
    padding: 0 19px;
    margin-bottom: 44px;

    @media ${deviceMax.tablet} {
        padding: 70px 0 0 0;
        margin-bottom: 0;

        &.popup-wrapper {
            padding-top: 18px;
            padding-left: 10px;
            padding-right: 10px;
            box-sizing: border-box;
        }
    }

    > div {
        padding-left: 16px;
        padding-right: 16px;

        @media ${deviceMax.tablet} {
            padding-left: 0;
            padding-right: 0;
        }
    }


    h1 {
        margin-top: 0;
        text-transform: uppercase;
        color: ${Colors.brandColor};
        letter-spacing: 0;
        font-size: 18px;
        line-height: 55px;
        font-weight: 800;

        &.popup-wrapper-title {
            line-height: 34px;
            margin-bottom: 20px;
        }

        @media ${deviceMax.tablet} {
            color: ${Colors.black};
            text-align: center;
            margin: 0 auto 24px;

            &.popup-wrapper-title {
                margin-left: 0;
            }
        }
    }
    
    h2 {
        margin-top: 32px;
        letter-spacing: 0;
        font-size: 18px;
        line-height: 30px;
        font-weight: 800;
    }

    ol {
        padding-left: 15px;
    }

    .listMarkerPink {
        list-style: disc;

        p, span {
            margin-left: -17px;
            color: ${Colors.black};
        }

        &::marker {
            font-weight: bold;
        }
    }

    .listMarkerBold {
        &::marker {
            font-weight: bold;
        }
    }

    ul {
        padding-left: 16px;

        @media ${deviceMax.tablet} {
            padding-left: 20px;
        }

        li {
            margin: 0;
            line-height: 20px;
            list-style: lower-latin;

            @media ${deviceMax.tablet} {
                font-size: 12px;
            }
        }
    }

    p {
        line-height: 20px;

        @media ${deviceMax.tablet} {
            font-size: 12px;
        }
    }
`;
