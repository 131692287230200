import React, {ChangeEvent, useEffect, useState, KeyboardEvent} from "react";
import CheckboxComp from "../checkboxComp/CheckboxComp";
import InputComp from "../buttons/inputComp/InputComp";
import SingleCheckboxAndText from "../checkboxComp/SingleCheckboxAndText";
import * as Styled from "./DesktopHeroAreaStyled";
import FacebookLoginButton from "../generalButtons/FacebookLoginButton";
import RegisterNow from "../generalButtons/RegisterNow";
import {ApiClassicRegistration} from "../../api/RegistrationServies";
import PopupComp from "../popup/PopupComp";
import LightingText from "../popup/LightingText";
import Image from "next/image";
import ElectronicMessage from "../popup/ElectronicMessage";
import ExpressConsentText from "../popup/ExpressConsentText";
import CustomAlert, {AlertType} from "../customAlert/CustomAlert";
import {isValidEmail} from "../../utils/ValidEmail";
import FacebookLoginButtonWhiteBackground from "../generalButtons/FacebookLoginButtonWhiteBackground";
import GoogleLoginButton from "../generalButtons/GoogleLoginButton";

const optionsGender = [
    {
        id: 'genderMan',
        name: 'gender',
        title: 'Erkek',
        value: '0',
        defaultChecked: true
    },
    {
        id: 'genderWoman',
        name: 'gender',
        title: 'Kadın',
        value: '1',
        defaultChecked: false
    }
];
const optionsLookingPerson = [
    {
        id: 'lookingPersonMan',
        name: 'lookingPerson',
        title: 'Erkek',
        value: '0',
        defaultChecked: false
    },
    {
        id: 'lookingPersonWoman',
        name: 'lookingPerson',
        title: 'Kadın',
        value: '1',
        defaultChecked: true
    }
];

const changeWriting = (e: ChangeEvent<HTMLInputElement>) => {
    // console.log(e.target.value);
}

type IProps = {
    advertisement?: string
}

const DesktopHeroArea = (props: IProps) => {
    const [checked, setChecked] = React.useState(true);
    const [checkedElectronic, setCheckedElectronic] = React.useState(true);
    const [checkedExpress, setCheckedExpress] = React.useState(true);

    const [email, setEmail] = React.useState('');
    const [gender, setGender] = React.useState<'0' | '1'>('0');

    const [preventAction, setPreventAction] = React.useState(false);

    const [showPopup, setshowPopup] = React.useState(false);
    const [showPopupElectronic, setshowPopupElectronic] = React.useState(false);
    const [showPopupExpress, setshowPopupExpress] = React.useState(false);

    const [alert, setAlert] = useState<{ message: string; type: AlertType } | null>(null);

    useEffect(() => {
        if (showPopupElectronic || showPopup || showPopupExpress) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'revert-layer';
        }
        return () => {
            document.body.style.overflow = 'unset';
        };
    }, [showPopupElectronic, showPopup, showPopupExpress]);

    const handleAlertClose = () => {
        setAlert(null);
    };

    const alertValidate = () => {
        if (!email) {
            handleAlertClose();
            setAlert({
                message: '<span class="color-text-warning">Hata! </span><span class="text-thin">Lütfen geçerli bir e-posta adresi veya telefon numarası giriniz.</span>',
                type: 'fail'
            });
        } else {
            startRegistration();
        }
    }

    const startRegistration = () => {

        if (preventAction) {
            return;
        }

        if (checked && checkedElectronic && checkedExpress) {
            setPreventAction(true);
            ApiClassicRegistration(
                email,
                (gender === '0') ? 0 : 1
            ).then(() => {
                setPreventAction(false);
            }).catch((error) => {
                setAlert({message:error,type:'fail'});
                setPreventAction(false);
            });
        } else {
            setAlert({
                message: '<span class="color-text-warning">Hata! </span><span class="text-thin">Kayıt için</span> <span>Kişisel Aydınlatma Metni</span> <span class="text-thin">ve</span> <span>Ticari Elektronik ileti</span> <span class="text-thin">iznini kabul etmeniz gerekiyor.</span>',
                type: 'fail'
            });
        }

    };

    return (
        <Styled.DesktopHeroAreaWrapper>
            <div className="wrapper">
                <Styled.StyledDesktopHeroArea>
                    <Styled.StyledDesktopHeroAreaTitle>
                        {props.advertisement ? props.advertisement : 'Ücretsiz Üye Ol'}
                    </Styled.StyledDesktopHeroAreaTitle>
                    {props.advertisement ? null : (
                        <Styled.StyledDesktopHeroAreaSubtitle>
                            Şimdi kayıt olun, size uygun üyeleri hemen görün. Ücretsiz kişilik testimizden yararlanın.
                            Profiliniz gizli kalsın.
                        </Styled.StyledDesktopHeroAreaSubtitle>
                    )}
                    <Styled.StyledDesktopHeroAreaButton>
                        <CheckboxComp
                            title={'Cinsiyetiniz:'}
                            options={optionsGender}
                            selectedValue={gender}
                            onChange={(newValue: '0' | '1') => {
                                setGender(newValue);
                            }}
                        />
                        <CheckboxComp
                            title={'Aradığınız Kişi:'}
                            options={optionsLookingPerson}
                            selectedValue={(gender === '1') ? '0' : '1'}
                            onChange={(newValue: '0' | '1') => {
                                let newGender: '0' | '1' = (newValue === '0') ? '1' : '0';
                                setGender(newGender);
                            }}
                        />
                    </Styled.StyledDesktopHeroAreaButton>
                    <Styled.StyledDesktopHeroAreaInput>
                        <InputComp title={'E-posta veya Telefon Numarası:'}
                                   placeholder={'E-posta veya Telefon Numarası'}
                                   changeWriting={(event: ChangeEvent<HTMLInputElement>) => {
                                       setEmail(event.target.value);
                                   }}
                                      value={email}
                                        onKeyDown={(event: KeyboardEvent<HTMLInputElement>) => {
                                            if (event.key === 'Enter') {
                                                alertValidate();
                                            }
                                        }}
                                   handleAlertClose={handleAlertClose}
                        />
                    </Styled.StyledDesktopHeroAreaInput>
                    <Styled.StyledDesktopHeroAreaLighting>
                        <SingleCheckboxAndText titleColor checked={checked} onChange={() => {
                            setChecked(!checked);
                            handleAlertClose();
                        }}>
                            Kişisel verilerimin işlenmesine ilişkin <button className={'lighting-button'}
                                                                            onClick={() => {
                                                                                setshowPopup(!showPopup);
                                                                                handleAlertClose();
                                                                            }}>Aydınlatma
                            Metni</button>ni okudum.
                        </SingleCheckboxAndText>
                        <SingleCheckboxAndText titleColor checked={checkedExpress} onChange={() => {
                            setCheckedExpress(!checkedExpress);
                            handleAlertClose();
                        }}>
                            Özel nitelikli kişilik testi bilgilerimin işlenmesini <button className={'lighting-button'}
                                                                            onClick={() => {
                                                                                setshowPopupExpress(!showPopupExpress);
                                                                                handleAlertClose();
                                                                            }}>Açık Rıza
                            Metni</button> kapsamında işlenmesini onay veriyorum.
                        </SingleCheckboxAndText>
                        <SingleCheckboxAndText titleColor checked={checkedElectronic}
                                               onChange={() => {
                                                   setCheckedElectronic(!checkedElectronic);
                                                   handleAlertClose();
                                               }}>
                            Ürün veya hizmetlere ilişkin kampanya ve duyurulardan haberdar olmak için tarafıma <button
                            className={'lighting-button'}
                            onClick={() => {
                                setshowPopupElectronic(!showPopupElectronic);
                                handleAlertClose();
                            }}>Açık Rıza
                            Metni</button> kapsamında ticari elektronik ileti gönderilmesini istiyorum.
                        </SingleCheckboxAndText>

                    </Styled.StyledDesktopHeroAreaLighting>
                    {alert && <CustomAlert message={alert.message} type={alert.type} margin={false}/>}
                    <Styled.StyledDesktopHeroAreaRegister>
                        <RegisterNow registerFunction={() => {
                            alertValidate();
                        }} isNonNavigationButton={true}/>
                    </Styled.StyledDesktopHeroAreaRegister>
                    <Styled.StyledDesktopHeroareaLine/>
                        <>
                            <Styled.StyledDesktopHeroAreaFacebookAndGoogleLogin>
                                <FacebookLoginButtonWhiteBackground/>
                                <GoogleLoginButton/>
                            </Styled.StyledDesktopHeroAreaFacebookAndGoogleLogin>
                        </>
                    <Styled.StyledDesktopHeroAreaImage>
                        <Image src={'/next/assets/images/digicert.png'} alt={'hero_area'} loading={'lazy'} width={129}
                               height={60}/>
                    </Styled.StyledDesktopHeroAreaImage>
                    {showPopup ? (
                        <PopupComp onClose={() => setshowPopup(!showPopup)}>
                            <LightingText showPopup/>
                        </PopupComp>
                    ) : null}
                    {showPopupElectronic ? (
                        <PopupComp onClose={() => setshowPopupElectronic(!showPopupElectronic)}>
                            <ElectronicMessage showPopup/>
                        </PopupComp>
                    ) : null}
                    {showPopupExpress ? (
                        <PopupComp onClose={() => setshowPopupExpress(!showPopupExpress)}>
                            <ExpressConsentText showPopup/>
                        </PopupComp>
                    ) : null}
                </Styled.StyledDesktopHeroArea>
            </div>
            <style jsx global>{`
              html {
                overflow: ${showPopup ? 'hidden' : 'unset'};
              }
            `}</style>
        </Styled.DesktopHeroAreaWrapper>
    )
};


export default DesktopHeroArea;
