import React from "react";
import styled from "styled-components";
import Colors from "../../constants/Colors";
import CheckboxButton from "../buttons/checkboxButton/CheckboxButton";

type IProps = {
    title?: string;
    options: any;
    selectedValue: any;
    onChange : (newValue: any)=>void;
    mobileRegister?: boolean;
}

const CheckboxComp = ({title, options,selectedValue,onChange, mobileRegister}: IProps) => {

    return (
        <StyledCheckboxComp>
            {title && (
                <CheckboxCompTitle>
                    {title}
                </CheckboxCompTitle>
            )}
            <CheckboxButtonListWrapper>
                <CheckboxButton
                    option={options}
                    selectedValue={selectedValue}
                    onChange={onChange}
                    mobileRegister={mobileRegister}
                />
            </CheckboxButtonListWrapper>
        </StyledCheckboxComp>
    );
}


const StyledCheckboxComp = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background: ${Colors.white};
  border-radius: 6px;
  padding: 10px 17px 16px;
`;

const CheckboxCompTitle = styled.div`
  margin-top: 0;
  margin-bottom: 7px;
  font-weight: 800;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: 0;
  color: ${Colors.checkboxTitleColor};
`;

const CheckboxButtonListWrapper = styled.div`
  position: relative;
  display: flex;

`;

export default CheckboxComp;
