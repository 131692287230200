import styled from "styled-components";
import Colors from "../../constants/Colors";
import {deviceMax} from "../../../styles/device";

export const DesktopHeroAreaWrapper = styled.div`
  position: relative;
  padding-left: calc(50vw - 575px);
  padding-right: calc(50vw - 575px);
  box-sizing: border-box;
  margin-top: 110px;
  
  > .wrapper {
    position: relative;
    width: 100%;
    padding: 0 25px;
    box-sizing: border-box;
  }
  
    @media ${deviceMax.tablet} {
      display: none;
    }
`;

export const StyledDesktopHeroArea = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  background: ${Colors.softBlackColor};
  border-radius: 6px;
  padding: 25px 28px 20px 28px;
  width: 100%;
  max-width: 491px;
`;

export const StyledDesktopHeroAreaTitle = styled.h1`
  font-family: 'Manrope', sans-serif;
  font-weight: 800;
  font-size: 18px;
  line-height: 24px;
  margin-top: 0;
  margin-bottom: 10px;
  letter-spacing: 0;
  text-transform: uppercase;
  color: ${Colors.white};
`;

export const StyledDesktopHeroAreaSubtitle = styled.div`
  font-family: 'Manrope', sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0;
  color: ${Colors.white};
  margin-bottom: 10px;
`;

export const StyledDesktopHeroAreaButton = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 7px;

  > div {
    &:first-child {
      margin-right: 6px;
    }
  }
`;

export const StyledDesktopHeroAreaInput = styled.div`
  position: relative;
  width: 100%;
`;

export const StyledDesktopHeroAreaLighting = styled.div`
  position: relative;
  width: 100%;
  margin-top: 14px;
  margin-bottom: 14px;

  .lighting-button {
    background-color: transparent;
    border: none;
    outline: none;
    padding: 0;
    margin: 0;
    font-family: 'Manrope', sans-serif;
    font-weight: 800;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0;
    color: ${Colors.white};
    cursor: pointer;
  }
`;

export const StyledDesktopHeroAreaRegister = styled.div`
  position: relative;

  > div {
    padding: 0;
    margin-bottom: 0;
  }
`;

export const StyledDesktopHeroareaLine = styled.div`
  position: relative;
  width: 100%;
  border: 1px solid ${Colors.white};
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const StyledDesktopHeroAreaFacebookLogin = styled.div`
  position: relative;

  > div {
    padding: 0;
    margin-bottom: 0;
  }
`;

export const StyledDesktopHeroAreaFacebookAndGoogleLogin = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;

    > div {
        padding: 0;
    }
`;

export const StyledDesktopHeroAreaNotPost = styled.div`
  position: relative;
  font-family: 'Manrope', sans-serif;
  font-weight: 800;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0;
  color: ${Colors.white};
  margin-top: 15px;
  margin-bottom: 15px;
`;

export const StyledDesktopHeroAreaImage = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

`;
