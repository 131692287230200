import React from "react";
import * as Styled from "./LayoutStyles";
import Footer from "../components/headerAndFooter/Footer";
import GlobalStyle from "../constants/GlobalStyle";
import Header from "../components/headerAndFooter/Header";

type IProps = {
    children? : React.ReactNode;
    heroArea? : JSX.Element;
    heroAreaBackgroundImage? : string;
    heroAreaBackgroundImageDesktop? : string;
    subHeroArea? : JSX.Element;
    bodyBackgroundColor? : boolean;
};

const LandingLayout = (props: IProps)=>{

    return(
        <React.Fragment>

            <GlobalStyle bodyBackgroundColor={props.bodyBackgroundColor}/>
            <Styled.GeneralPage>

                <Header
                    headerBackground={'dark'}
                    loginButton
                    sloganOne={'Ayda 90 bin Yeni Kayıt'}
                    sloganTwo={'15 yıldır yanınızda'}
                />

                <Styled.LandingHeroArea
                    className={'heroArea'}
                    backgroundImageMobile={props.heroAreaBackgroundImage}
                    backgroundImageDesktop={props.heroAreaBackgroundImageDesktop}
                >
                    <div className={'linearGradient'}></div>
                    {props.heroArea}
                </Styled.LandingHeroArea>

                {props.subHeroArea?(
                    <div className={'subHeroArea'}>
                        {props.subHeroArea}
                    </div>
                ):null}

                {props.children?(
                    <div className={'content'}>
                        {props.children}
                    </div>
                ):null}

                <Footer/>
            </Styled.GeneralPage>


        </React.Fragment>
    );

};

export default LandingLayout;
