import styled from "styled-components";
import Colors from "../../constants/Colors";
import {deviceMax} from "../../../styles/device";

export const PopupWrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.40);
  overflow: hidden;
`;

export const PopupContent = styled.div`
  position: absolute;
  left: 20%;
  right: 20%;
  top: 15%;
  bottom: 10%;
  margin: auto;
  background: white;
  overflow-x: hidden;
  overflow-y: scroll;
  fill: #FFF;
  filter: drop-shadow(0px 24px 32px rgba(0, 0, 0, 0.32));
  border-radius: 20px 20px 0 0;
  padding-bottom: 43px;
  box-sizing: content-box;
  z-index: 2;

  @media ${deviceMax.tablet} {
    left: 10%;
    right: 10%;
  }

  &::-webkit-scrollbar {
    overflow: hidden;
    border-radius: 20px;
    z-index: 1;
  }

  &::-webkit-scrollbar-track {
    overflow: hidden;
    z-index: 1;
    //border-radius: 0 20px 0 0;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0 20px 0 0;
    overflow: hidden;
    z-index: 1;
    background-color: ${Colors.brandColor};
  }

`;

export const PopupClose = styled.div`
  position: absolute;
  right: 17px;
  top: 17px;
  cursor: pointer;
  z-index: 9999;

  > svg {
    width: 17px;
    height: 17px;
    
    @media ${deviceMax.tablet} {
        width: 14px;
        height: 14px;
    }
  }
`;

export const PopupCloseButton = styled.button`
  cursor: pointer;
  background: ${Colors.brandColor};
  border: none;
  outline: none;
  padding: 0;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 200px;
  border-radius: 8px;
  transition: all .3s ease;

  &:hover {
    background: ${Colors.brandColorHover};
  }

  > p {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    color: ${Colors.white};
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0;
    margin: 0;
    padding: 12px 0;
    text-transform: uppercase;
  }

`;

