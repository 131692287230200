import React from "react";
import Colors from "../../constants/Colors";
import ButtonComp from "../buttons/buttonComp/buttonComp";
import {NavigationURLs} from "../../functions/NavigationFunctions";

const RegisterNow = ({
                         registerFunction = () => {
                         },
                         isNonNavigationButton = false,
    
                     }) => {
    const buttonUrl = isNonNavigationButton ? '#!' : NavigationURLs.landing;

    return (
        <ButtonComp
            backgroundColor={Colors.brandColor}
            visible={true}
            fullWidth={true}
            textColor={true}
            uppercase={true}
            career={true}
            onClick={() => {
                if (isNonNavigationButton) {
                    registerFunction();
                }
            }}
            url={buttonUrl}
            dataTestId='registerNowButton'
        >
            {'Hemen Kayıt Ol'}
        </ButtonComp>
    );
};


export default RegisterNow;
