import React from "react";
import TextArea from "../textArea/TextArea";
import {LightingTextWrapper} from "./LightingTextStyled";

type IProps = {
    showPopup?: boolean;
}

const ExpressConsentText = ({
                                showPopup = false
                            }: IProps) => {
    return (
        <LightingTextWrapper className={showPopup ? 'popup-wrapper' : undefined}>
            <TextArea>
                <h2 className={showPopup ? 'popup-wrapper-title' : undefined}>Pembe Panjur Açık Rıza Beyanı</h2>
                <p>
                    6698 sayılı Kişisel Verilerin Korunması Kanunu'na (Kanun) göre sizinle ilgili bazı kişisel
                    verilerin, veri sorumlusu olan Panjur Bilişim ve Danışmanlık Hizmetleri Ticaret A.Ş. (Panjur
                    Bilişim), tarafından işlenebilmesi için açık rızanız gerekmektedir.
                </p>

                <p>
                    Pembe Panjur klasik bir evlilik sitesi değildir. Öncelikli amacı mutlu, uzun ve sağlıklı birliktelik
                    yaşayabilecek ciddi ilişki niyetindeki üyeleri birbirleriyle tanıştırmaktır. Bu amaçla üyelerimiz
                    arasında hem sosyo-ekonomik hem de kişilik testi sonuçlarına dayalı eşleştirme yapılır. Sitede
                    tercihe bağlı olarak sadece birbirleriyle eşleştirilmiş üyeler birbirlerini görebilir. Üyeler
                    birbirleriyle yirminin üzerinde eksende eşleştirilirler. Üyeler hayat standartlarına uygun ve
                    duygusal olarak birbirlerini tamamlayacak kişilerle eşleştirilirler. Eşleştirme algoritmamızda yer
                    tutan ağırlıklar bile sizin verdiğiniz cevaplar doğrultusunda size özel olarak değişirler.
                </p>

                <p>
                    Bu kapsamda kişilik testine verdiğiniz sonuçlar ile kişilik sonuçların işlenmesine üyelik
                    faaliyetlerinin yürütülmesi, hizmet operasyon süreçlerinin yürütülmesi, size en uygun adayın tespit
                    edilebilmesi ve saklama süreçlerinin yürütülmesi amaçlarıyla Kanun’un 6/2 maddesinde düzenlenen özel
                    nitelikli kişisel verilerinizin işlenmesine ilişkin açık rızanızın bulunması durumunda işlenecektir.
                </p>

                <p>
                    Açık rıza vermek için ilgili kutucuğu işaretlemeniz gerekmektedir.
                </p>
            </TextArea>
        </LightingTextWrapper>
    )

}

export default ExpressConsentText;