import React from "react";
import styled from "styled-components";
import Colors from "../../../constants/Colors";

import {darken} from "polished";

type IProps = {
    option?: any;
    selectedValue: any;
    onChange : (newValue: any)=>void;
    mobileRegister?: boolean;
};

const CheckboxButton = ({option,selectedValue,onChange, mobileRegister = false}: IProps) => {
    return (
        <StyledCheckboxButton>
            <fieldset>
                {
                    option.map((item: any, index: any) => {

                        return(
                            <div key={index}>
                                {
                                    mobileRegister ? (
                                        <div className={'mobileRegister'}>
                                            <input
                                                type="radio"
                                                id={item.id}
                                                name={item.id}
                                                value={item.value}
                                                checked={item.value === selectedValue}
                                                onChange={(event) => {
                                                    onChange(event.target.value);
                                                }}
                                            />
                                            <label htmlFor={item.id} id={`lab` + item.id}>{item.title}</label>
                                            <span className="checkmark"></span>
                                        </div>
                                    ) : (
                                        <React.Fragment>
                                            <input
                                                type="radio"
                                                id={item.id}
                                                name={item.id}
                                                value={item.value}
                                                checked={item.value === selectedValue}
                                                onChange={(event) => {
                                                    onChange(event.target.value);
                                                }}
                                            />
                                            <label htmlFor={item.id} id={`lab` + item.id}>{item.title}</label>
                                            <span className="checkmark"></span>
                                        </React.Fragment>
                                    )
                                }

                            </div>
                        );
                    })}
            </fieldset>
        </StyledCheckboxButton>
    );
}

const StyledCheckboxButton = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    //z-index: 2;

    > fieldset {
        position: relative;
        display: flex;
        border: none;

        > div {
            position: relative;
            display: block;
            cursor: pointer;
            user-select: none;

            &:not(:first-child) {
                margin-left: 14px;
            }

            > label {
                display: block;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: 0;
                color: ${Colors.blackColor};
                padding-left: 41px;
                padding-top: 3px;
                cursor: pointer;
            }

            &:hover {
                input ~ .checkmark {
                    background-color: ${darken(0.09, Colors.white)};

                    &:after {
                        border: solid ${Colors.white};
                        border-width: 0 2px 2px 0;
                    }
                }
            }

            > input:checked ~ .checkmark {
                background-color: ${Colors.checkboxCheckColor};
            }

            > .checkmark:after {
                left: 10px;
                top: 7px;
                width: 5px;
                height: 10px;
                border: solid white;
                border-width: 0 2px 2px 0;
                -webkit-transform: rotate(35deg);
                -ms-transform: rotate(35deg);
                transform: rotate(35deg);
            }

            > input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                height: 29px;
                width: 29px;
                margin: 0;
                z-index: 2;
            }

            > .checkmark {
                position: absolute;
                top: 0;
                left: 0;
                height: 29px;
                width: 29px;
                background-color: ${Colors.checkboxUncheckColor};
                border-radius: 50%;

                &:after {
                    content: "";
                    position: absolute;
                    display: block;
                }
            }

            > .title {
                display: block;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: 0;
                color: ${Colors.blackColor};
                padding-left: 41px;
                padding-top: 3px;
            }
            
            > div.mobileRegister {
                position: relative;
                cursor: pointer;
                user-select: none;
                width: 100%;
                max-width: 70px;
                margin: 0 auto;

                > input {
                    position: absolute;
                    opacity: 0;
                    cursor: pointer;
                    margin: 0;
                    z-index: 2;
                }

                > label {
                    display: block;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    letter-spacing: 0;
                    color: ${Colors.blackColor};
                    cursor: pointer;
                }

                > .checkmark {
                    position: absolute;
                    width: 16px;
                    height: 16px;
                    background-color: ${Colors.white};
                    border: 1px solid ${Colors.black};
                    border-radius: 50%;

                    &:after {
                        content: "";
                        position: absolute;
                        display: block;
                        border: none;
                    }
                }
            }
        }

    }
`;

export default CheckboxButton;
